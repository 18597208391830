<template>
  <div>
    <v-dialog width="400" v-model="dialog.flag">
      <v-card>
        <v-card-title
          class="primary_2 text-h6 white--text py-3 d-flex justify-space-between"
        >
          <div>
            <span class="text-capitalize">{{ dialog.action }}</span> Alternate
            Mobile Number
          </div>
          <v-btn fab text small @click="dialog.flag = false">
            <v-icon color="white" large>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="dialog.action == 'add'">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="addAlternateMobile()"
            >
              <v-row>
                <v-col cols="12" class="pb-0 pt-6">
                  <div>Alternate Mobile Number</div>
                  <v-text-field
                    v-model="dialog.alternate_number"
                    outlined
                    dense
                    maxlength="10"
                    :rules="$rules.MOBILE"
                    placeholder="Enter Added Alternate Number"
                    required
                    counter="10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex justify-end">
              <v-btn
                :disabled="!valid"
                color="secondary_2"
                :disable="btn_loader"
                :loading="btn_loader"
                class="px-4 mt-4 font-weight-bold white--text"
                @click="addAlternateMobile()"
              >
                Add
              </v-btn>
            </div>
          </div>
          <div v-if="dialog.action == 'edit'">
            <v-form
              ref="edit"
              v-model="is_edit_valid"
              lazy-validation
              @submit.prevent="editAlternateMobile()"
            >
              <v-row>
                <v-col cols="12" class="pb-0 pt-6">
                  <div>Alternate Mobile Number</div>
                  <v-text-field
                    v-model="dialog.alternate_number"
                    outlined
                    dense
                    :rules="$rules.MOBILE"
                    placeholder="Enter Added Alternate Number"
                    required
                    maxlength="10"
                    counter="10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex justify-end">
              <v-btn
                :disabled="!is_edit_valid"
                color="secondary_2"
                :disable="btn_loader"
                :loading="btn_loader"
                class="px-4 mt-4 font-weight-bold white--text"
                @click="editAlternateMobile()"
              >
                Edit
              </v-btn>
            </div>
          </div>
          <div v-else-if="dialog.action == 'delete'">
            <div class="mt-4 text-body-1">
              Are you sure you want to delete this alternate number:
              <span
                class="font-weight-black"
              >{{ dialog.alternate_number }}</span>
            </div>
            <div class="d-flex justify-end">
              <v-btn
                :disabled="!is_edit_valid"
                color="red"
                :disable="btn_loader"
                :loading="btn_loader"
                class="px-4 mt-4 font-weight-bold white--text"
                @click="deleteAlternateMobile()"
              >
                Delete
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      valid: true,
      is_edit_valid: true,
      btn_loader: false,
    };
  },
  methods: {
    addAlternateMobile() {
      if (this.$refs.form.validate()) {
        const self = this;

        self.btn_loader = true;

        let body = {
          customer_id: self.decrypt(this.$route.params.id),
          alternate_number: self.dialog.alternate_number,
        };

        const successHandler = (response) => {
          self.dialog.flag = false;
          self.$emit("success");
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.ADD_ALTERNATE_NUMBER,
          body,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
    editAlternateMobile() {
      if (this.$refs.edit.validate()) {
        const self = this;

        self.btn_loader = true;

        let params = self.dialog.id;
        // let params = 9879

        let body = {
          alternate_number: self.dialog.alternate_number,
        };

        const successHandler = (response) => {
          // console.log("PATCH local res out", response);
          self.dialog.flag = false;
          self.$emit("success");
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        self.PATCH_request(
          self,
          self.$urls.UPDATE_ALTERNATE_NUMBER,
          params,
          null,
          body,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
    },
    deleteAlternateMobile() {
      const self = this;

      self.btn_loader = true;

      let lookup_key = self.dialog.id;
      // let params = 9879

      const successHandler = (response) => {
        // console.log("DELETE local res out", response);
        self.dialog.flag = false;
        self.$emit("success");
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };
      self.DELETE_request(
        self,
        self.$urls.UPDATE_ALTERNATE_NUMBER,
        lookup_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
